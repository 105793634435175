/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  useMediaQuery, InputLabel, Select, MenuItem, FormControl, Button, TextField, Link, Snackbar, IconButton, Tooltip, FormControlLabel, Checkbox, Typography, OutlinedInput, InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { sessionActions } from '../store';
import { useLocalization, useTranslation } from '../common/components/LocalizationProvider';
import LoginLayout from './LoginLayout';
import usePersistedState from '../common/util/usePersistedState';
import { handleLoginTokenListeners, nativeEnvironment, nativePostMessage } from '../common/components/NativeInterface';
import LogoImage from './LogoImage';
import { useCatch, useEffectAsync } from '../reactHelper';
import Logo from '../resources/images/TreLogo.svg';
import { decrypted, encrypted } from '../payment/components/utils';

const useStyles = makeStyles((theme) => ({
  options: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
  },
  extraContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: theme.spacing(2),
  },
  resetPassword: {
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const t = useTranslation();

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const [failed, setFailed] = useState(false);

  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
  const [email, setEmail] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const registrationEnabled = useSelector((state) => state.session.server.registration);
  const languageEnabled = useSelector((state) => !state.session.server.attributes['ui.disableLoginLanguage']);
  const emailEnabled = useSelector((state) => state.session.server.emailEnabled);

  const [announcementShown, setAnnouncementShown] = useState(false);
  const announcement = useSelector((state) => state.session.server.announcement);
  useEffect(() => {
    setLanguage('ja');
  }, []);
  const generateLoginToken = async () => {
    if (nativeEnvironment) {
      let token = '';
      try {
        const expiration = moment().add(6, 'months').toISOString();
        const response = await fetch('/api/session/token', {
          method: 'POST',
          body: new URLSearchParams(`expiration=${expiration}`),
        });
        if (response.ok) {
          token = await response.text();
        }
      } catch (error) {
        token = '';
      }
      nativePostMessage(`login|${token}`);
    }
  };

  const handlePasswordLogin = async (event) => {
    const encryptedPasword = await encrypted(password);
    event.preventDefault();
    try {
      const response = await fetch('/api/session', {
        method: 'POST',
        body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(encryptedPasword)}`),
      });
      if (response.ok) {
        const user = await response.json();
        if (user.administrator === true) {
          generateLoginToken();
          dispatch(sessionActions.updateUser(user));
          if (rememberMe === true) {
            localStorage.setItem('email', await encrypted(email));
            localStorage.setItem('password', await encrypted(password));
          } else {
            localStorage.removeItem('email');
            localStorage.removeItem('password');
          }
          navigate('/admin/home/list');
        } else {
          throw Error(await response.text());
        }
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      setFailed(true);
      setPassword('');
    }
  };

  const handleTokenLogin = useCatch(async (token) => {
    const response = await fetch(`/api/session?token=${encodeURIComponent(token)}`);
    if (response.ok) {
      const user = await response.json();
      dispatch(sessionActions.updateUser(user));
      navigate('/');
    } else {
      throw Error(await response.text());
    }
  });

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && email && password) {
      handlePasswordLogin(e);
    }
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => nativePostMessage('authentication'), []);

  useEffect(() => {
    const listener = (token) => handleTokenLogin(token);
    handleLoginTokenListeners.add(listener);
    return () => handleLoginTokenListeners.delete(listener);
  }, []);

  useEffectAsync(async () => {
    let savedEmail = localStorage.getItem('email');
    let savedPassword = localStorage.getItem('password');
    if (rememberMe) {
      try {
        savedEmail = await decrypted(savedEmail);
        savedPassword = await decrypted(savedPassword);
      } catch {
        savedEmail = null;
        savedPassword = null;
      }
      if (savedEmail && savedPassword) {
        setEmail(savedEmail);
        setPassword(savedPassword);
      } else setRememberMe(false);
    }
  }, []);

  useEffectAsync(async () => {
    if (rememberMe && email && password) {
      localStorage.setItem('email', await encrypted(email));
      localStorage.setItem('password', await encrypted(password));
    } else {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }
  }, [rememberMe]);

  return (
    <LoginLayout>
      <div className={classes.options}>
        {nativeEnvironment && (
          <Tooltip title={t('settingsServer')}>
            <IconButton onClick={() => navigate('/change-server')}>
              <LockOpenIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div>
        <div className="dis-logo">
          <LogoImage color={theme.palette.primary.main} />
        </div>
        <div className="container-page-login">
          <div className="block-field">
            <Typography>{t('loginId')}</Typography>
            <TextField
              className="custom-text-field"
              required
              error={failed}
              name="email"
              value={email}
              autoComplete="email"
              autoFocus={!email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={handleSpecialKey}
              helperText={failed && 'Invalid username or password'}
            />
          </div>
          <div className="block-field">
            <Typography>{t('userPassword')}</Typography>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                className="custom-text-field"
                required
                id="password"
                type={showConfirmPassword ? 'text' : 'password'}
                value={password}
                error={failed}
                autoFocus={!!email}
                autoComplete="current-password"
                onKeyUp={handleSpecialKey}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                   )}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="block-field field-remember-pass">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={rememberMe}
                  onChange={(event) => {
                    setRememberMe(event.target.checked);
                    localStorage.setItem('rememberMe', event.target.checked);
                  }}
                  name="rememberMe"
                  color="primary"
                />
        )}
              label={t('loginRemember')}
            />
          </div>

          <div className="block-field">
            <Button
              className="btn-perform"
              onClick={handlePasswordLogin}
              onKeyUp={handleSpecialKey}
              variant="contained"
              fullWidth
              disabled={!email || !password}
            >
              {t('loginLogin')}
            </Button>
          </div>
          <div className="block-field  block-reset">
            <Button
              onClick={() => navigate('/reset-password')}
              className="register-button"
            >
              {t('loginReset')}
            </Button>
          </div>
          {/* Lý do comment đoạn code này: figma không có design nhưng trong quá trình phát triển web, dev vẫn cần chức năng chuyển đổi ngôn ngữ. Spec chốt ẩn phần này */}
          {/* <div className="block-field">
            {languageEnabled && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{t('loginlanguage')}</Typography>
              <FormControl sx={{ paddingLeft: '10px' }}>
                <Select
                  value={language}
                  onChange={(e) => {
                    setLanguage(e.target.value);
                  }}
                >
                  {languageList.map((it) => <MenuItem key={it.code} value={it.code}>{it.name}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
            )}
          </div> */}

        </div>

        {/* <div className={classes.extraContainer}>
          <Button
            className={classes.registerButton}
            onClick={() => navigate('/register')}
            disabled={!registrationEnabled}
            color="secondary"
          >
            {t('loginRegister')}
          </Button>
          {emailEnabled && (
          <Link
            onClick={() => navigate('/reset-password')}
            className={classes.resetPassword}
            underline="none"
            variant="caption"
          >
            {t('loginReset')}
          </Link>
          )}
          <Button
            onClick={() => navigate('/reset-password')}
            className={classes.registerButton}
            color="secondary"
          >
            {t('loginReset')}
          </Button>
        </div> */}
      </div>

      <Snackbar
        open={!!announcement && !announcementShown}
        message={announcement}
        action={(
          <IconButton size="small" color="inherit" onClick={() => setAnnouncementShown(true)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      />
    </LoginLayout>
  );
};

export default LoginPage;
