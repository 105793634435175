import React, { useEffect, useState } from 'react';
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, FormControl, MenuItem, TextField, tableCellClasses, Snackbar, IconButton,
  Radio,
  FormControlLabel,
  RadioGroup,
  Button,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../Header';
import ConfirmationPopup from '../ComfirmationPopup';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { useCatch } from '../../../../reactHelper';
import { BillingMethod, RatePlan, PaymentMethod } from '../../../common/DefineClass';
import { helperActions } from '../../../../store';
import { snackBarDurationVeryLongMs } from '../../../../common/util/duration';
import CustomDatePicker from '../../../components/CustomDatePicker';
import CustomInputComponent from '../../../components/CustomInputComponent';
import ModalConfirmAdmin from '../../../modal/ModalConfirm';
import { billingMethod, categoryType, paymentMethod } from '../../../common/const';
import Vectorback from '../../../../resources/images/Vectorback.png';
import fetchApi from '../../../FetchApi';
import { formatLocalToUTC } from '../../../../common/util/convertTimeZone';
import { encrypted } from '../../../../payment/components/utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F5F5F5',
    border: '1px solid #C2C2C2',
    color: '#3E3E3E',
  },
}));

const useStyle = makeStyles({
  required: {
    fontSize: '20px',
    '&::before': {
      content: '"*"',
      color: 'red',
      position: 'relative',
      top: '0',
    },
    '&.MuiButtonBase-root': { backgroundColor: 'red' },
  },
  autofill: {
    fontSize: '20px',
    '&::before': {
      content: '"*"',
      color: 'white',
      position: 'relative',
      top: '0',
    },
  },
  backBtn: {
    backgroundColor: '#fff',
    display: 'flex',
    color: '#7f7f7f',
    border: '1px solid #7f7f7f',
    height: '48px',
    width: '123px',
    justifyContent: 'space-evenly',
    fontSize: '16px',
    borderRadius: '30px',
    marginLeft: 30,
    padding: '20px',
  },
  createBtn: {
    width: '123px',
    backgroundColor: '#02AA9EB2',
    display: 'flex',
    color: 'white',
    height: '48px',
    justifyContent: 'space-evenly',
    fontSize: '16px',
    borderRadius: '30px',
    marginLeft: 30,
    padding: '20px',
    '&:disabled': {
      backgroundColor: '#C0C0C0',
    },
    '&:hover': {
      backgroundColor: '#02AA9EB2',
      color: 'white',
    },
  },
  borderCell: {
    height: '100px',
    border: '1px solid #C2C2C2',
  },
  selectField: {
    '&:hover': { backgroundColor: '#de560a' },
    '&:focus': { backgroundColor: '#de560a' },
  },
  datePicker: {
    border: '1px solid #ced4da',
    borderRadius: '4px',
    padding: '8px',
    width: '120px',
    fontWeight: 400,
    fontSize: '1rem',
  },
});

const RepresentAccount = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();

  const isMobile = useMediaQuery('(max-width:600px)');

  const [category, setCategory] = useState(1);
  const [paymentStatusCode, setPaymentStatusCode] = useState('newApplication');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('');

  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [statusTime, setStatusTime] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [idRedirect, setIdRedirect] = useState(null);

  const handleCloseModal = () => {
    setOpenModal(false);
    setCategory(1);
    setPaymentStatusCode('newApplication');
    setName('');
    setPassword('');
    setEmail('');
    setNote('');
    setStartDate(Date.now());
    setEndDate(Date.now());
    setStatusTime(1);
  };
  const handleChangeRadioOption = (event, params) => {
    setStatusTime(parseInt(event.target.value, 10));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleOpenSnackbar = () => {
    setOpen(true);
  };
  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  const handleActionDetailEditTable = () => {
    if (idRedirect) {
      navigate(`/admin/home/list/details/${idRedirect}/edit`);
    }
  };
  const hasEmptyName = (() => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const emailRegex = /^(?!.*\.\.)(?!.*^-)(?!.*-$)(?!^\.)(?!\.$)([a-zA-Z0-9]+([._+-][a-zA-Z0-9]+)*)@([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.[a-zA-Z]{2,})$/;
    const currentDate = new Date().toISOString().split('T')[0];
    const useStartDate = moment(startDate).format('YYYY-MM-DD');
    const useEndDate = moment(endDate).format('YYYY-MM-DD');
    if (name.length === 0) {
      setErrorMessage('editvalidate');
    }
    if (!emailRegex.test(email)) {
      setErrorMessage('messageEmailValid');
      return true;
    }
    if (!passwordRegex.test(password)) {
      setErrorMessage('messagePasswordValid');
      return true;
    }
    if (useStartDate < currentDate) {
      setErrorMessage('validateEditAccountMember2');
      return true;
    }
    if (statusTime === 0) {
      if (useEndDate < currentDate || useStartDate > useEndDate) {
        setErrorMessage('validateEditAccountMember');
        return true;
      }
    }
    return false;
  });

  const handleCreateAccount = useCatch(async () => {
    if (hasEmptyName()) {
      handleOpenSnackbar();
    } else {
      const response = await fetchApi('/api/admin/members/register/manager', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          category,
          paymentStatusCode: 'newApplication',
          name,
          disabled,
          email,
          password: await encrypted(password),
          billingMethod: category === 1 ? billingMethod[1].value : billingMethod[0].value,
          lastBillingDate: formatLocalToUTC(startDate),
          nextBillingDate: statusTime === 0 ? formatLocalToUTC(endDate, true) : formatLocalToUTC('9999/12/31'),
          paymentMethod: category === 1 ? paymentMethod[1].value : paymentMethod[0].value,
          category1: 0,
          rateplan: '1_month',
          typeApp: null,
          note,
        }),
      });
      if (response.ok) {
        const result = await response.json();
        dispatch(helperActions.setPathname(location.pathname));
        if (result.status === 'NG' && result.error !== null) {
          setOpen(true);
          setErrorMessage(result.error);
        } else {
          setOpenModal(true);
          setIdRedirect(result?.data?.id);
        }
      }
    }
  });

  const handleChange = (event, callback) => {
    callback(event.target.value);
  };

  useEffect(() => {
    if (category === 0) {
      setStartDate(Date.now());
      setEndDate(Date.now());
    }
  }, [category]);

  const specialDisable = !(name.length !== 0 && category !== undefined && disabled !== '' && email.length !== 0 && password.length !== 0 && startDate !== null && statusTime !== undefined);

  return (
    <div>
      <Header listPath="tools/representAccount/pathCreateAccountRepresent" noDescription noBack topic="Create a manager account">
        <p style={{ fontWeight: 700, fontSize: '20px', margin: '2px 0' }}>{t('titleRepresentAccount')}</p>
        <div style={{ margin: '2px 0', display: 'flex' }}>
          <p>{t('desRepresentative')}</p>
          <Button className={classes.backBtn} onClick={() => navigate('/admin/tools')}>
            <img style={{ marginRight: '10px' }} src={Vectorback} alt="icon back" />
            {t('back')}
          </Button>
          <Button disabled={specialDisable} className={classes.createBtn} onClick={() => handleCreateAccount()}>
            {t('sharedPerform')}
          </Button>
        </div>
      </Header>
      <ModalConfirmAdmin
        openModal={openModal}
        handleClose={handleCloseModal}
        action={handleActionDetailEditTable}
        propsModal={{
          confirm: t('messageConfirmCreateRepresentAccount1'),
          subTitle: t('messageConfirmCreateRepresentAccount2'),
          subTitle2: t('messageConfirmCreateRepresentAccount3'),
          titleCancel: t('sharedNo'),
          titleContinue: t('sharedYes'),
        }}
      />
      <div style={{ margin: '20px' }}>
        <TableContainer component={Paper} sx={{ borderRadius: 0, boxShadow: '5px 5px 20px 0px #D3D3D3' }}>
          <Table aria-label="collapsible table">
            <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
              <TableRow>
                <StyledTableCell align="center">{ t('categoryTableHeader1')}</StyledTableCell>
                <StyledTableCell align="center">{t('categoryTableHeader2')}</StyledTableCell>
                <StyledTableCell align="center">{t('categoryTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('nameTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('userIdTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('userStatusTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('userEmailTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('passwordTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('startDateTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('endDateTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('noteTableHeader')}</StyledTableCell>

                {/* <StyledTableCell align="center">{t('paymentIdTableHeader')}</StyledTableCell> */}
                {/* {category === 1 && (
                  <>
                    <StyledTableCell align="center">{t('billingMethodTableHeader')}</StyledTableCell>
                    <StyledTableCell align="center">{t('ratePlanTableHeader')}</StyledTableCell>
                    <StyledTableCell align="center">{t('paymentTableHeader')}</StyledTableCell>
                    <StyledTableCell align="center">{t('expiryDateTableHeader')}</StyledTableCell>
                  </>
                )} */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.borderCell}>
                  <Typography sx={{ width: '120px' }}>{t('smartPhone')}</Typography>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <Typography sx={{ width: '50px' }}>{t('representative')}</Typography>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <Typography sx={{ width: '60px' }}>{t('accountSpecial')}</Typography>
                </TableCell>

                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      sx={{ width: '180px' }}
                      name="name"
                      value={name}
                      autoComplete="name"
                      onChange={(e) => handleChange(e, setName)}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <Typography sx={{ width: '120px' }}>{t('autoGenarateId')}</Typography>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  {/* <FormControl fullWidth>
                    <Select
                      labelId="select-disabled"
                      id="disabled"
                      sx={{ width: '120px' }}
                      value={disabled}
                      onChange={(e) => handleChange(e, setDisabled)}
                    >
                      <MenuItem className={classes.selectField} value>{t('stopped')}</MenuItem>
                      <MenuItem className={classes.selectField} value={false}>{t('inUse')}</MenuItem>
                    </Select>
                  </FormControl> */}
                  <Typography sx={{ width: '70px' }}>{t('inUse')}</Typography>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      sx={{ width: '200px' }}
                      name="email"
                      value={email}
                      autoComplete="email"
                      onChange={(e) => handleChange(e, setEmail)}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      sx={{ width: '180px' }}
                      name="name"
                      value={password}
                      autoComplete="password"
                      onChange={(e) => handleChange(e, setPassword)}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl sx={{ width: '160px' }} fullWidth>
                    <DatePicker
                      portalId="root"
                      selected={startDate}
                      dateFormat="yyyyMMdd"
                      className={classes.datePicker}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onChange={(selectedDate) => {
                        setStartDate(selectedDate);
                      }}
                      customInput={<CustomInputComponent />}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl sx={{ width: '196px !important' }} fullWidth>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={statusTime}
                      onChange={(e) => handleChangeRadioOption(e)}
                      className="date-editor"

                    >
                      <FormControlLabel
                        value={1}
                        control={(
                          <Radio icon={<RadioButtonUncheckedIcon className="radio-button" />} checkedIcon={<RadioButtonCheckedIcon className="radio-button-checked" />} />)}
                        label={t('unLimited')}
                      />
                      <FormControlLabel
                        value={0}
                        control={(
                          <Radio icon={<RadioButtonUncheckedIcon className="radio-button" />} checkedIcon={<RadioButtonCheckedIcon className="radio-button-checked" />} />)}
                        label={t('limited')}
                      />

                      {statusTime === 0 && (
                      <FormControl fullWidth>
                        <DatePicker
                          portalId="root"
                          selected={endDate}
                          dateFormat="yyyyMMdd"
                          className={classes.datePicker}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onChange={(selectedDate) => {
                            setEndDate(selectedDate);
                          }}
                          customInput={<CustomInputComponent />}
                        />
                      </FormControl>
                      )}
                    </RadioGroup>
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      sx={{ width: '180px' }}
                      name="note"
                      value={note}
                      onChange={(e) => handleChange(e, setNote)}
                    />
                  </FormControl>
                </TableCell>
                {/* <TableCell className={classes.borderCell}>
                  <FormControl fullWidth >
                    <TextField
                      required
                      sx={{ width: '180px' }}
                      name="name"
                      value="tre-app-123456789"
                      autoComplete="name"
                      autoFocus
                      // onChange={(event) => setName(event.target.value)}
                      disabled
                    />
                  </FormControl>
                </TableCell> */}
                {/* {category === 1 && (
                <>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth>
                      <Select
                        labelId="select-billing-method"
                        id="billingMethod"
                        sx={{ width: '120px' }}
                        value={billingMethod}
                        onChange={(e) => handleChange(e, setBillingMethod)}
                      >
                        <MenuItem className={classes.selectField} value={1}>{t(billing.inApp)}</MenuItem>
                        <MenuItem className={classes.selectField} value={2}>{t(billing.outside)}</MenuItem>
                        <MenuItem className={classes.selectField} value={3}>{t(billing.special)}</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth>
                      <Select
                        labelId="select-rate-plan"
                        id="ratePlan"
                        sx={{ width: '120px' }}
                        value={ratePlan}
                        onChange={(e) => handleChange(e, setRatePlan)}
                      >
                        <MenuItem className={classes.selectField} value={rate.basic}>{t(rate.basic)}</MenuItem>
                        <MenuItem className={classes.selectField} value={rate.prenium}>{t(rate.prenium)}</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth>
                      <Select
                        labelId="select-payment-method"
                        id="paymentMethod"
                        sx={{ width: '120px' }}
                        value={paymentMethod}
                        onChange={(e) => handleChange(e, setPaymentMethod)}
                      >
                        <MenuItem className={classes.selectField} value={1}>{t(payment.inApp)}</MenuItem>
                        <MenuItem className={classes.selectField} value={2}>{t(payment.credit)}</MenuItem>
                        <MenuItem className={classes.selectField} value={3}>{t(payment.bank)}</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth>
                      <DatePicker
                        portalId="root"
                        selected={date}
                        dateFormat="yyyy/MM/dd"
                        className={classes.datePicker}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={(selectedDate) => {
                          setDate(selectedDate?.getTime());
                        }}
                      />
                    </FormControl>
                  </TableCell>
                </>
                )} */}
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* <ConfirmationPopup
        btnTitle={t('sharedCreateSpecialAccount')}
        submit={handleCreateAccount}
        confirmText={t('confirmCreateAccount')}
        btnColor="#f91414"
        width="330px"
        disabled={category === 1 ? specialDisable : generalDisable}
        styleButton={centerStyle}
      /> */}
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={snackBarDurationVeryLongMs}
        message={t(errorMessage)}
        action={action}
      />
    </div>
  );
};

export default RepresentAccount;
