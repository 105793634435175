/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { MenuItem } from '@mui/material';

const { REACT_APP_SECRETKEY } = process.env;

const SALT_LENGTH = 16;
const IV_LENGTH = 12;
const TAG_LENGTH = 16;
const ITERATIONS = 10000;
const KEY_LENGTH = 256;

function getRandomBytes(length) {
  return crypto.getRandomValues(new Uint8Array(length));
}

async function deriveKey(password, salt) {
  const encoder = new TextEncoder();
  const keyMaterial = await crypto.subtle.importKey(
    'raw',
    encoder.encode(password),
    'PBKDF2',
    false,
    ['deriveKey'],
  );
  return crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt,
      iterations: ITERATIONS,
      hash: 'SHA-512',
    },
    keyMaterial,
    { name: 'AES-GCM', length: KEY_LENGTH },
    false,
    ['encrypt', 'decrypt'],
  );
}

export async function encrypted(plainText) {
  if (plainText) {
    const encoder = new TextEncoder();
    const salt = getRandomBytes(SALT_LENGTH);
    const iv = getRandomBytes(IV_LENGTH);
    const key = await deriveKey(REACT_APP_SECRETKEY, salt);
    const encryptedContent = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv, tagLength: TAG_LENGTH * 8 },
      key,
      encoder.encode(plainText),
    );
    const encryptedBytes = new Uint8Array(
      salt.length + iv.length + encryptedContent.byteLength,
    );
    encryptedBytes.set(salt);
    encryptedBytes.set(iv, salt.length);
    encryptedBytes.set(new Uint8Array(encryptedContent), salt.length + iv.length);
    let binaryString = '';
    encryptedBytes.forEach((byte) => {
      binaryString += String.fromCharCode(byte);
    });
    return btoa(binaryString);
  }
  return '';
}

export async function decrypted(cipherText) {
  const decoder = new TextDecoder();
  const encryptedBytes = Uint8Array.from(atob(cipherText), (c) => c.charCodeAt(0));
  const salt = encryptedBytes.slice(0, SALT_LENGTH);
  const iv = encryptedBytes.slice(SALT_LENGTH, SALT_LENGTH + IV_LENGTH);
  const encryptedContent = encryptedBytes.slice(SALT_LENGTH + IV_LENGTH);
  const key = await deriveKey(REACT_APP_SECRETKEY, salt);
  const decryptedContent = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv, tagLength: TAG_LENGTH * 8 },
    key,
    encryptedContent,
  );
  return decoder.decode(decryptedContent);
}

export const combinedString = (year, month) => {
  const combinedString = year.toString() + month.toString().padStart(2, '0');

  return combinedString;
};

export const renderTimeOptions = (start, maxTime) => {
  const options = [];
  // eslint-disable-next-line no-plusplus
  for (let time = start; time <= maxTime; time++) {
    const timeFormatted = time < 10 ? `0${time}` : `${time}`;
    options.push(
      <MenuItem key={time} value={time}>
        {timeFormatted}
      </MenuItem>,
    );
  }
  return options;
};
